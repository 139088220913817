.contacts {
    background-color: rgb(36, 35, 35);
    width: 100%;
    height: 100vh;
    position: relative;

    &__inner {
      padding-top: 100px;
      padding-bottom: 200px;
    }


}