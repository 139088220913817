.footer {
background-color: black;
color: white;
    &__inner {
        padding-top: 65px;
        padding-bottom: 120px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap:20px ;
cursor: context-menu!important;
    }

.left__side{
    .logo {
        display: flex;
        gap: 10px;
        cursor: pointer!important;
        img {
            width: 55px;
            height: 55px;
        }
        align-items: center;
        text-align: left;
        &__title {
            width: 100%;

            .logo__header-title {
                font-family: 'Benzin';
                font-size: 22px;
                font-weight: bold;
            }
            .logo__down-title {
                font-family: 'Vis';
                font-size: 14px;
                font-weight: light;
            }
        }
    }


    .social__footer{
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 70px;

        span {
            font-size: 18px;
            font-family: 'Benzin';
            letter-spacing: 1.4px;
        }
    }
}

.footer__center{
    display: flex;
    gap: 100px;

    .footer__block-title {
        font-size: 24px;
        font-family: 'Benzin';
     
    }
    .footer__navigation {
        display: flex;
        flex-direction: column;
      
        &-list {
            display: flex;
            flex-direction: column;
            margin-top:20px ;
            gap: 20px;
            font-size: 20px;
            font-family: 'Vis';
        }


    }
    .footer__contacts {
    
        .contacts__block {
            margin-top:20px ;
            display: flex;
            flex-direction: column;
            .clients {
                margin-top:16px ;
            }
        }
       }
}

.footer__right {

    .footer__block-title {
        font-size: 24px;
        font-family: 'Benzin';
     
    }

    .footer__location {
        font-family: 'Vis';
        font-size: 20px;
        .address{
            margin-top: 20px;
        }
        .email {
            margin-top: 20px;
        }
    }
}

}



// MEDIA FOOTER

@media screen and (max-width:860px) {
    .footer {
     
        &__inner {
  display: flex;
  flex-direction: column;
        }
    }
}
@media screen and (max-width:486px) {
    .footer {
     
.footer__center {
    display: flex;
    flex-direction: column;
}
    }
}