/* Regular */
@font-face {
    font-family: 'Benzin';
    src: url('../assets/fonts/Benzin-Regular.ttf') format('truetype');
    font-weight: normal;
}
/* Regular */
@font-face {
    font-family: 'Vis';
    src: url('../assets/fonts/VisueltPro-Regular.ttf') format('truetype');
    font-weight: normal;
}