.header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    color:white;
    z-index: 999;
    // margin-top: 60px;
    padding: 10px;
    background-color: rgba(0,0,0,0.8);

    &__inner {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
 .burger__menu {
    display: none;
 }
    .logo {
        display: flex;
        gap: 10px;
        cursor: pointer!important;
        img {
            width: 55px;
            height: 55px;
        }
        align-items: center;
        text-align: left;
        &__title {
            width: 100%;
        }
    }




    .nav {
        display: flex;
        align-items: center;
        text-align: center;
        font-family: 'Vis';
        font-size: 18px;
        cursor: context-menu!important;
        ul {
            display: flex;
            gap: 40px;
            align-items: center;
            text-align: center;
            position: relative;
            

        }
    }


    .private__cabinet {
        display: flex;
        align-items: center;
        text-align: center;
        text-align: left;
        gap: 8px;
        background: #FFFFFF1A;
        justify-content: center;
        width: 170px;
        cursor: context-menu;
    }
    .private__cabinet-wrapper{
   cursor: pointer!important;
    }

.noise__overlay {
    background-image: url('../../assets/png/filter_crop.png');
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: contain;
}

.header__menu-item {
    position: relative;


}
.submenu {
    .header__submenu {
        position: absolute;
        // background-color: rgba(0,0,0,0.6);
        border-radius: 3px;
        padding: 20px;
        top: 20;
        width: 300px;
        // height: 400px;
        left: -100px;
        // display: flex;
        flex-direction: column;
        display: none;
        opacity: 0;
 
        text-align: left;
        gap: 10px;
        transition: all 1s ease;
        background-color: rgba($color: #FFFFFF, $alpha: .8);

   

    }
    &:hover > .header__submenu {
        display: flex;
        opacity: 1;
        text-align: left;

        transition: all 1s ease;
    }
    .header__submenu-item {
        transition: all 0.4s ease;
        text-align: left;
        display: flex;
        width: 100%;
        font-family: 'Benzin';
        font-size: 18px;
        cursor: pointer!important;
        color: black;
      
 
        &:hover {
            text-decoration: underline;
   
            transition: all 0.4s ease;
        }
    }
}
}









@media screen and (max-width: 960px) {
    
   .header {
    position: fixed;
    top: 0;
    .burger__menu {
       display: block;
       cursor:pointer;
  

       img {
        width: 100%;
        object-fit: cover;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
       }
     }
  
    &__inner {

justify-content: space-between;
align-items: center;
.burger__menu{
    margin-left: 100px;
}
    }
    .header__nav {
        display: none;
    }
    .private__cabinet{
        display: none;
    }
   }
  }
@media screen and (max-width: 600px) {
    


   .header {
    .logo {
   
        img {
            width: 46px;
            height: 46px;
        }
        align-items: center;
        text-align: left;
        &__title {
            width: 100%;

            .logo__little-title{
                font-size: 16px;
            }
            .logo__info{
                font-size: 10px;
            }
        }
    }
   }

  }