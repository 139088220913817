ul {
    list-style: none;
}
a {
    text-decoration: none;
    color: inherit;
}

.social__bar.row {
        display: flex;
        flex-direction: row;
        gap: 10px;
}

input {
    padding: 0;
    border: none;
    outline: none;
    background-color: transparent;
    border-bottom: 1px solid hsl(0, 0%, 55%);
    padding-bottom: 14px;
    

}
input::placeholder {
    color: white;
}


#map {
    g {
        position: relative;

       .info {
        position: absolute;
        width: 300px;
        height: 400px;
        display: none;
       }
       .info .active {
        display: flex;
        z-index: 999999;
        width: 300px;
        height: 400px;
        background-color: black;
       }

g:hover {
    fill: black;
}
   
    }
}


// .map__card{
//     position: absolute;
//     width: 1000px;
//     height: 100vh;
//     z-index: 999999999;
// }


*{
    outline: none;
}






#ict {
    position: relative;
}

.scroll_to_top_btn {
    position: fixed;
    bottom: 20px;
    right: 20px;
    cursor: pointer!important;
    // border-radius: 20px;
    background-color: transparent;
    border: none;
    outline: none;
}


.pulse {
    animation: pulse 1s infinite ease-in-out alternate;
  }
  @keyframes pulse {
    from { transform: scale(1); }
    to { transform: scale(1.1); }
  }



  .section-title {
    font-size: 80px;
  }

  .text-justify {
    text-align: center;
  }