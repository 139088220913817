.cabinet__card{
    background-color: white;
    margin-top: 10px;
    color: black;
    padding: 30px 40px;
    .card__number {
        font-size: 78px;
        font-family: 'Benzin';
        border-left:7px solid black;
        padding-left: 10px;
        margin-bottom: 10px;
    }
    .card__title {
        font-family: 'Benzin';
        font-size: 24px;
        max-width: 400px;
    }

    .card__info {
        margin-top: 10px;
        color: #1B1B1B;
        font-family: 'Vis';
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 40px;
    }
}