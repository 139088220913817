.container {
    width: 85%;
    margin: 0 auto;
}


@media screen and (max-width:660px) {
   .container {
    width: 85%;
   }
}