.burger {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: black;
    z-index: 99999;

    color: white;

    &__close {
        font-size: 40px;
        top: 10px;
        right: 10px;
        position: absolute;
        cursor:pointer;
    }
    &-inner {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        height: 100vh;
      ul {
        font-size: 30px;
        display: flex;
        flex-direction: column;
        gap: 30px;
        text-decoration: underline;
        font-family: 'Benzin';
      }
    }

}