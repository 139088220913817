.blog {
background-color: black;
color: white;
    &__inner {
        padding-top: 240px;

        h1 {
            font-family: 'Benzin';
            font-size: 80px;
        }

        .blog__items {
            width: 100%;
            display: flex;
            gap: 20px;
            flex-wrap: wrap;   
       
        }
    }
}

@media screen and (max-width:1150px) {
    .blog {
        background-color: black;
        color: white;
            &__inner {
                padding-top: 240px;
        
                h1 {
                    font-family: 'Benzin';
                    font-size: 120px;
                }
        
                .blog__items {
                    display: flex;
                    justify-content: space-between;
                    gap: 20px;
                    flex-wrap: wrap;   


                    .blog__item {
                        width: 44%;
                    }
               
                }
            }
        }
}
@media screen and (max-width:768px) {
    .blog {
        background-color: black;
        color: white;
            &__inner {
                padding-top: 140px;
                
        
                h1 {
                    font-family: 'Benzin';
                    font-size: 40px;
                    margin-bottom: 40px;
                }
        
                .blog__items {
                    display: flex;
                    justify-content: space-between;
                    gap: 20px;
                    flex-wrap: wrap;   


                    .blog__item {
                        width: 100%;
                    }
               
                }
            }
        }
}