.service{
    background-color: black;
    color: white;
    &__hero {
        width: 100%;
        height: 100vh;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .container__service-link{
        width: 100%;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        background-color: rgb(79, 79, 221);
        height: 40px;
        a {
            font-family: 'VIS';
            font-size: 20px;
        }
    }
    &__inner {
        padding-top: 240px;
        h1 {
            max-width: 580px;
            font-size: 54px;
            font-family: 'Benzin';
            margin-bottom: 40px;
        }
      
        .service__page-animation-desc{
            align-items: center;
            text-align: center;
            max-width: 800px;
            font-size: 20px;
            background: rgba(255, 255, 255, 0.13);
            // border-radius: 16px;
            box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
            backdrop-filter: blur(5px);
            -webkit-backdrop-filter: blur(5px);
            padding: 20px;
        }
        p{
            font-size: 18px;
            font-family: "Vis";
            color: hsl(0, 0%, 55%);
            margin-top:10px ;
            max-width: 580px;
        }
    }


    &__description {
        padding-top:120px ;
        button {
            border: none;
            outline: none;
            padding: 30px 30px;
            font-family: 'Benzin';
            font-size: 18px;
            margin-top: 34px ;
            cursor: pointer!important;
        }

        .article {
            display: block;
            font-family:'Vis';
            font-size: 24px;
            // padding: 20px;
            line-height: 1.2;
            white-space: pre-line;
            width: 100%;
            margin: 0 auto;
            background-color: rgba(18, 44, 66,0.1);
            transform: skewX(-10deg);
            text-align: left;    
            // width: 80%;          
           
        }
&-title {
    position: relative;
    h2 {
        font-size: 78px;
        max-width: 680px;
        font-family: 'Benzin';
    }
    .section__little-title {
        position: absolute;
        top: 0;
        right: 0;
        color: hsl(0, 0%, 35%);
        font-family: "Vis";
        font-weight: 400;
        font-size: 24px;
        line-height: 30px;
        align-items: left;
        text-align: left;
    }
}
    }
.stages__work-inner {
    display: grid;
    width: 100%;
    grid-template-columns: 44% 44%;
    justify-content: space-between;
    gap: 20px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    padding:40px 0px;
    margin-top: 10px;

}
    .stage__item {
        border-top: 1px solid rgba(255,255,255,0.1);
        padding: 10px;
display: flex;
flex-direction: column;
&-body {
    font-size: 18px;
    font-family: 'Vis';
    margin-top: 20px;
    padding: 0px 40px;


    p {

        font-size: 18px;
        font-family: 'Vis';
    }

   }

&-top {
    display: flex;
    align-items: center;
    text-align: center;
    gap: 20px;
    width: 100%;
    position: relative;
 
    h3 {
        font-size: 28px;
        width: 100%;
        text-align: left;
        font-family: 'Benzin';
        font-size: 28px;
    }
}
        .little__img {
            position: relative;
            width:50%;
            height: 200px;
            background-size: cover;
            background-repeat: no-repeat;
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
            padding: 60px;

            img {
                position: absolute;
                width: 100%;
                object-fit: contain;
            
            }

            span {
                color: black;
                font-size:36px ;
                font-family: 'Benzin';
                z-index: 9999;

            }
        }

    }

}





@media screen and (max-width:1130px) {
    .service{
        background-color: black;
        color: white;
        &__hero {
            width: 100%;
            height: 100vh;
            background-size: cover;
            background-repeat: no-repeat;
        }
    
        &__inner {
            padding-top: 240px;
            h1 {
                max-width: 580px;
                font-size: 54px;
                font-family: 'Benzin';
            }
            p{
                font-size: 18px;
                font-family: "Vis";
                color: hsl(0, 0%, 55%);
                margin-top:10px ;
                max-width: 580px;
            }
        }
    
    
        &__description {
            padding-top:120px ;
    &-title {
        position: relative;
        h2 {
            font-size: 78px;
            max-width: 680px;
            font-family: 'Benzin';
        }
        .section__little-title {
            position: absolute;
            top: 0;
            right: 0;
            color: hsl(0, 0%, 35%);
            font-family: "Benzin";
            font-weight: 400;
            font-size: 24px;
            line-height: 30px;
            align-items: left;
            text-align: left;
        }
    }
        }
    .stages__work-inner {
        display: grid;
        width: 100%;
        grid-template-columns: 1fr;
        justify-content: space-between;
        gap: 20px;
        border-top: 1px solid white;
        border-bottom: 1px solid white;
        padding:40px 0px;
        margin-top: 10px;
    
    }
        .stage__item {
          
            padding: 10px;
    display: flex;
    flex-direction: column;
    &-body {
        font-size: 18px;
        font-family: 'Vis';
        margin-top: 20px;
        padding: 0px 40px;
    
    
        p {
    
            font-size: 18px;
            font-family: 'Vis';
        }
    
       }
    
    &-top {
        display: flex;
        align-items: center;
        text-align: center;
        gap: 20px;
        width: 100%;
        position: relative;
     
        h3 {
            font-size: 28px;
            width: 100%;
            text-align: left;
        }
    }
            .little__img {
                position: relative;
                width:50%;
                height: 200px;
                background-size: cover;
                background-repeat: no-repeat;
                display: flex;
                align-items: center;
                text-align: center;
                justify-content: center;
                padding: 60px;
    
                img {
                    position: absolute;
                    width: 100%;
                    object-fit: contain;
                
                }
    
                span {
                    color: black;
                    font-size:36px ;
                    font-family: 'Benzin';
                    z-index: 9999;
    
                }
            }
    
        }
    
    }
    
}
@media screen and (max-width:660px) {
    .service{
        background-color: black;
        color: white;
        &__hero {
            width: 100%;
            height: 100vh;
            background-size: contain;
            background-position: right right center;
        }
    
        &__inner {
            padding-top: 240px;
            display: flex;
            flex-direction: column;
            align-items: center ;
            text-align: center;
            h1 {
                max-width: 580px;
                width: 100%;
                font-size: 24px;
                font-family: 'Benzin';
                margin-bottom: 10px;
            }
            p{
                font-size: 16px;
                font-family: "Vis";
                color: hsl(0, 0%, 55%);
                margin-top:10px ;
                max-width: 580px;
                width: 100%;
            }
        }
    
    
        &__description {
            padding-top:10px ;
    &-title {
        position: relative;
        h2 {
            font-size: 40px;
            max-width: 680px;
            font-family: 'Benzin';
            padding-top: 100px;
        }
        .section__little-title {
            position: absolute;
            top: 0;
            left: 0;
            color: hsl(0, 0%, 35%);
            font-family: "Benzin";
            font-weight: 400;
            font-size: 24px;
            line-height: 30px;
            align-items: left;
            text-align: left;
        }
    }
        }
    .stages__work-inner {
        display: grid;
        width: 100%;
        grid-template-columns: 1fr;
        justify-content: space-between;
        gap: 20px;
        border-top: 1px solid white;
        border-bottom: 1px solid white;
        padding:40px 0px;
        margin-top: 10px;
    
    }
        .stage__item {
            border-top: 1px solid rgba(255,255,255,0.1);
            padding: 10px;
    display: flex;
    flex-direction: column;
    &-body {
        font-size: 14px;
        font-family: 'Vis';
        margin-top: 20px;
        padding: 0px 40px;
    
    
        p {
    
            font-size: 14px;
            font-family: 'Vis';
        }
    
       }
    
    &-top {
        display: flex;
        align-items: center;
        text-align: center;
        gap: 20px;
        width: 100%;
        // height: 100px;
        position: relative;
        flex-direction: column;
     
        h3 {
            font-size: 18px;
            width: 100%;
            text-align: center;
        }
    }
            .little__img {
                position: relative;
                width:50%;
                height: 100px;
                background-size: cover;
                background-repeat: no-repeat;
                display: flex;
                align-items: center;
                text-align: center;
                justify-content: center;
                padding: 60px;
    
                img {
                    position: absolute;
                    width: 100%;
                    object-fit: contain;
                
                }
    
                span {
                    color: black;
                    font-size:24px ;
                    font-family: 'Benzin';
                    z-index: 9999;
    
                }

            }
    
        }
    
    }

    
    
}


