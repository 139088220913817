.free__consultation {

    display: flex;
    align-items: center;
    background-color: white;
    color: black;

 

    &-inner {

        padding: 80px 0px;

      

    }

    &-block {
       
        display: flex;
        justify-content: space-between;
        gap: 60px;
        
.left{
// width: 100%;
margin-top: 40px;
    h3 {
        font-family: 'Benzin';
        font-size: 78px;
        line-height: 73px;
      
    }
    .free__consultation-sub-title {
        font-family: 'Benzin';
        font-size: 28px;
        margin-top: 50px;
        // background-color: red;
    }

}

    form {
        // width: 100%;
        // width: 100%;
        background-color: #062033;
        padding: 48px;
        max-width:520px;
        width: 100%;
        height:auto;
        display: flex;
        align-items: center;
        text-align: center;
        flex-direction: column;
    .form__control {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 100px;
        &.second {
            margin-top: 40px;
        }
    }
        
      input {
        font-size: 18px;
        font-family: 'Benzin';
        width: 100%;
        color: white;
        &::placeholder {
            color: gray;
        }
      }
      .form__control .phone {
        background-color: transparent;
        padding: 4px;
        font-family: 'VIS';
        height: 40px;
      }
      textarea {
        width: 100%;
        height: 150px;
    padding: 20px;
    box-sizing: border-box;
    resize: none;
    font-family: 'Benzin';
    font-size: 18px;



      }
      /* General scrollbar styles */
textarea::-webkit-scrollbar {
    width: 12px;
}

/* Thumb styles for WebKit browsers (Chrome, Safari) */
textarea::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 6px;
}

/* Hover effect for thumb in WebKit browsers */
textarea::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* Track styles for WebKit browsers */
textarea::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 6px;
}

/* Thumb styles for Firefox */
textarea {
    scrollbar-width: thin; /* Thin scrollbar */
    scrollbar-color: #888 #f1f1f1; /* Thumb color and track color */
}

/* Specific styling for Firefox scrollbar thumb and track */
textarea::-moz-scrollbar-thumb {
    background: #888;
    border-radius: 6px;
}

textarea::-moz-scrollbar-thumb:hover {
    background: #555;
}

textarea::-moz-scrollbar-track {
    background: #f1f1f1;
    border-radius: 6px;
}

      .textarea {
        background-color: transparent;
        color: white;
        width: 100%;
        &::placeholder {
            color: gray;
        }
      }

      button {
        border: none;
        outline: none;
        padding: 30px 30px;
        font-family: 'Benzin';
        font-size: 18px;
        margin-top: 34px ;
        cursor: pointer!important;
      }
    }


    }
}



// MEDIA 

@media screen and (max-width:1130px) {
    .free__consultation-block{
        display: flex;
        flex-direction: column;
    }
    .free__consultation-block .left h3 {
        max-width: 90%;
        font-size: 60px;
       
    }
}
@media screen and (max-width:576px) {
    .free__consultation-block{
        display: flex;
        flex-direction: column;
    }
    .free__consultation-block .left h3 {
        max-width: 60%;
        font-size: 30px;
       
    }
}
@media screen and (max-width:580px) {
    .free__consultation-block{
        display: flex;
        flex-direction: column;
    }
    .free__consultation-block .left h3 {
        max-width: 90%;
        font-size: 60px;
       
    }
}
@media screen and (max-width:576px) {
    .free__consultation-block{
        display: flex;
        flex-direction: column;
    }
    .free__consultation-block .left h3 {
        max-width: 60%;
        font-size: 30px;
       
    }

    .free__consultation-form{
        .phone{
            input {
                width: 70%;
            }
        }
    }
}