.service__card {
    display: flex;
    max-width: 100%;
    width: 46%;
    // border: 10px solid transparent;
    box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;

    height: 500px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    transition: transform 0.3s ease;

   cursor: context-menu!important;

    &:hover{
        transform: scale(1.01);
        transition: transform 0.3s ease;
    }


.card__inner  {
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;





    .card__title {
    font-family: 'Benzin';
    font-size: 30px;
    line-height: 34px;

    }


    .card__info {
        font-family: 'Vis';
        font-size: 20px;
        line-height: 21px;
        margin-top: 10px;
        color: hsl(0, 0%, 75%);
        margin-top: 20px;
        max-width: 70%;
    }


    .card__button {
        padding:35px ;
        border: 1px solid white;
        max-width: 60%;
        text-align: center;
        align-items: center;
        box-shadow: 0px 4.20651388168335px 11.567912101745605px 0px #0000002B;

        font-family: 'Benzin';
        font-size: 16px;
        line-height: 23pxs;
    }
}

    


}

@media screen and (max-width:960px) {
.service__card {
    width: 100%;
}
}
@media screen and (max-width:768px) {
.service__card{

    .card__inner{
        padding: 2px;
        .card__button {
            padding: 12px;
        }
    }
}
}