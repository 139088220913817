.map {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    height: 100vh;
    svg {
        max-width: 100%;
        max-height: calc(100% - 30px);
    }
}

.region {
    position: relative;
    // fill: #008080;
    fill: #207180;
    background-image: url("../../../assets/images/cargo-price.jpg");
    cursor: pointer!important;
    path {
        transition: fill 0.3s;
        position: relative;
  > g {
    position: absolute;
    top: 0;
    right: 0;
    fill:blue;
}
    }

    &Disabled {
        pointer-events: none;

        path {
            fill: #606060;
        }
    }
}

