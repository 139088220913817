.hero {
    position: relative;
    width: 100%;
    height:100vh; /* Высота геро-секции */
    overflow: hidden; /* Скрытие части изображения, которая не помещается */
    // background-image: url('../../assets/images/hero.jpg');
    background-image: url('../../assets/images/main-test-photos/test3.jpg');

    background-size: cover; /* растянуть изображение на весь фон */
    background-position: center; /* расположить изображение по центру */
    background-repeat: no-repeat;


video {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.4;
  z-index: 1;
  background-position: center right right;
  background-size: contain;
}


.noise-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../assets/png/filter_crop.png');
  opacity: 0.5; /* Прозрачность шума */
}





// HERO!!!

&__inner {
  text-align: left;
  color: white;
  margin-top: 260px;
  position: relative;

  h1 {
    font-family: 'Benzin';
    font-size: 90px;
    line-height: 115px;
    letter-spacing: 5%;
  }
  p{
    font-family: 'Benzin';
    font-size: 20px;
    line-height: 19px;
    letter-spacing: 5%;
  }

.social__bar{
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

}


.hero__about {

  display: flex;
  justify-content: space-between;
  margin-top: 200px;





  &-left{
    width: 50%;
    display: flex;
    align-items: center;
    text-align: center;
    width: 540px;
    gap: 10px;


    p{
      font-family: 'Vis';
      font-weight: 400;
      color: #FFFFFF;
      // width: 240px;
      // text-align: left;
      background-color: rgba($color: #000000, $alpha: .6);
      padding: 10px;
      border-radius: 3px;
      // color: hsl(0, 0%, 80%);
      // text-align: justify;
      // color: black;
      // font-size: 24px;

    }

    button {
     border: none;
     outline: none;
     padding: 30px 60px;
     background-color: #94BBE6;
     font-family: 'Benzin';
     font-size: 18px;
     line-height: 25px;
     cursor: pointer;
      
    }
  }
  &-right {
    position: relative;
    width: 50%;
    
    // background-color: red;

    span {
      position: absolute;
      bottom: 0;
      right: 0;
      font-family: 'Benzin';
      font-size: 16px;
      color: hsl(0, 0%, 80%);

    }
  }

}

}


@media screen and (max-width:876px) {
  .hero{
    background-color: black;
    background-image: none;

    &__inner {
      margin-top: 140px;
      .hero__info {
        // margin-top: 10px;
        display: flex;
        align-items: center;
        text-align: center;
        flex-direction: column;
        h1 {
          font-size: 60px;
        }
      }
    }
  }
  .hero__about-right {
    display: none;
  
  }
}
@media screen and (max-width:666px) {
  .hero{
    background-color: black;
    background-image: none;

    &__inner {
      margin-top: 140px;
      .hero__info {
        // margin-top: 10px;
        display: flex;
        align-items: center;
        text-align: center;
        flex-direction: column;
        h1 {
          font-size: 60px;
        }
      }
    }



    .hero__about {
     display: flex;
    //  flex-direction: column;
     margin-top: 100px;

     &-left {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
     }
    }
  }
}