.not__found {
    width: 100%;
    height: 70vh;
    background-color: black;
    color: white;
    
    &-inner {
        padding-top: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        h1 {
            font-size: 120px;
            font-family: 'Benzin';
        }
        p {
            font-size: 40px;
            font-family: 'Benzin';
        }
    }
}