.our__clients {
    

    &-inner {
            // margin-top: 110px;
            padding: 110px 0px;
            display: flex;
            flex-direction: column;
            position: relative;
            h2 {
                font-family: 'Benzin';
                font-size: 80px;
                line-height: 130px;
                // max-width:750px
            }    
}

.clients__block {
    margin-bottom: 100px;
    display: flex;
    gap: 100px;
    .our__client {
        width: 200px;
        height: 200px;
        background-color: #062033;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        margin-left: 100px;

    }
}
}


@media screen  and (max-width:960px){
    .our__clients {
    

        &-inner {
                // margin-top: 110px;
                padding: 110px 0px;
                display: flex;
                flex-direction: column;
                position: relative;
                h2 {
                    font-family: 'Benzin';
                    font-size: 60px;
                    line-height: 80px;
                    // max-width:750px
                    margin-bottom: 100px;
                }    
    }
    
    .clients__block {
        margin-top: 0px;
        margin-bottom: 10px;
        display: flex;
        gap: 40px;
        .our__client {
            width: 100px;
            height: 100px;
            background-color: #062033;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            margin-left: 30px;
    
        }
        img {
            width: 100%;
            // height: 100%;
        }
    }
    }
}
@media screen  and (max-width:660px){
    .our__clients {
        &-inner {
                padding: 40px 0px;
                display: flex;
                flex-direction: column;
                position: relative;
                h2 {
                    font-family: 'Benzin';
                    font-size: 40px;
                    line-height: 80px;
                    margin-bottom: 10px;
                }    
    }
    
    .clients__block {
        margin-bottom: 100px;
        display: flex;
        gap: 40px;
        .our__client {
            width: 100px;
            height: 100px;
            background-color: #062033;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            margin-left: 30px;
    
        }
        img {
            width: 100%;
            // height: 100%;
        }
    }
    }
}